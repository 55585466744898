import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import {isLoggedIn,APIHOST,loadError} from '../../utils/gaia';
import Buttontb from '../Components/Button'
import Header from '../Components/Header'
import Axios from 'axios';
import moment from 'moment';

const Report = (props) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/`)
    }
    loadHistory()
  },[])

  const loadHistory = () =>{
    setLoading(true)
    Axios.post(`${APIHOST}member/plusHistory`, {}).then(function(res){
        let {data} = res;
        setLoading(false)
        setHistory(data.history.data)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  return(
    <>
      <Header nav="scan" logged={true} />
      <div id="landing">
        <div className="inner">
          <h1>Activity Log</h1>
          <div className='history'>
            {
              history.map(his=>(
                <div className='item'>
                  <div className='item-id'>{his.mid}</div>
                  <div className='item-action'>{his.plus > 50000 ? 50000 : his.plus} chance(s) awarded</div>
                  <div className='item-date'>{moment(his.created_at).format("YYYY-MM-DD HH:mm")}</div>
                </div>
              ))
            }
            
          </div>
        </div>
      </div>
    </>
  )
}

export default Report;
