import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import {isLoggedIn,isOutletSet, loadError, APIHOST, loadSuccess} from '../../utils/gaia';
import Buttontb from '../Components/Button'
import Header from '../Components/Header'
import Axios from 'axios';
import ScanModal from '../Components/ScanModal'
import { Form, FormGroup, Label, Input } from 'reactstrap';

import {Images} from '../../utils/images'

const Home = (props) => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [mid, setMid] = useState("");
  const [weight, setWeight] = useState("");

  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/`)
    }
  },[])

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(mid == ""){
      loadError("Please scan a Qr Code first.");
      return
    }

    if(weight == "" || isNaN(weight) || parseInt(weight) < 0){
      loadError("Please enter a valid weight in KG.");
      return
    }

    setLoading(true)
    Axios.post(`${APIHOST}member/awardPlus`, {
      mid: mid,
      weight: weight
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        loadSuccess("")
        setTimeout(()=>{  window.location.replace(`/app`)},1500)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const toggleModal = () =>{
    setModal(!modal);
  }

  return(
    <>
      <Header nav="report" logged={true} />
      <div id="landing">
        <div className="inner">
          <h1>Backend Settings</h1>
          <div className='actions'>
            <a onClick={toggleModal}><img src={Images.scan} className="icon" /></a>
            {mid != "" && <div className='form'>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username">ID Number</Label>
                  <Input className="text-field" disabled={true} type="text" id="mid" name="mid" placeholder='ID Number' value={mid} onChange={event => setMid(event.target.value)} required />
                </FormGroup>
                <FormGroup  className="mb-4">
                  <Label for="username">Key in weight in KG</Label>
                  <Input className="text-field" type="text" id="weight" name="weight" placeholder='' onChange={event => setWeight(event.target.value)} required />
                </FormGroup>
                <Buttontb loader={loading} text={"SUBMIT"} classList="tmpl-main-btn" />
              </Form>
            </div>}
          </div>
        </div>
      </div>
      {modal && <ScanModal setMid={setMid} toggle={toggleModal} modal={modal} />}
    </>
  )
}

export default Home;
